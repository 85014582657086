@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background: linear-gradient(90deg, #1c19e4, #b81edb);
   */
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);

  font-family: "Poppins", sans-serif;
  color: #fbfbfb;
  text-shadow: 1px 1px 1px #333;
  overflow-wrap: break-word;
}

a,
li {
  text-decoration: none;
  list-style-type: none;
  color: #fbfbfb;
}
a:hover {
  text-decoration: underline;
}
.logo {
  display: inline-block;
  height: 23px;
  border-radius: 50%;
  margin-left: 0.5rem;
}
.btn {
  font-weight: 600;
  color: dark;
  font-family: inherit;
  background-color: #fbfbfb;
  border-color: #7952b3;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.7rem;
  border-radius: 0.25rem;
  transition: 0.3s ease;
  box-shadow: 2px 2px 7px 1px #666;
  line-height: 1;
  top: 1.5rem;
  
  
}

.btn:hover {
  background: rgba(253, 253, 253, 0.5);
  transition: 0.3s ease;
}

.nav {
  font-size: 1.4rem;
  padding-top: 4rem;
  padding-left: 6rem;
  color: #fbfbfb;
  display: flex;
  align-items: center;
}

.nav a {
  color: #fbfbfb;
}

.nav .nav-links {
  position: relative;
  right: -400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav .nav-links li {
  margin-left: 2rem;
}

.nav .nav-links li a:hover {
  opacity: 0.5;
}

.nav .nav-links .btn {
  margin-left: 2rem;
}

.nav .fa-bars,
.nav .fa-times {
  cursor: pointer;
  display: none;
}

.nav .nav-links.active {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background: rgba(3, 3, 3, 0.7);
  padding: 15vh 0;
  font-size: 2rem;
  z-index: 2;
}
/* the close class will hide the bars  */
.nav .fa-bars.close {
  display: none;
}

/* the open class will show the X  */

.nav .fa-times.open {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
  z-index: 2;
}

.container {
  max-width: 1440px;
  height: 80vh;
  margin: auto;
  margin-top: 10vh;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 10px 10px 25px 5px #111;
  animation: fadeIn 4s ease-out;
}

h2 {
  font-size: 4rem;
}

h4 {
  font-weight: 100;
  font-size: 2.5rem;
}

p {
  margin: 1rem 0;
  color: #ddd;
}
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
input {
  margin-bottom: 1.8rem;
  padding: 0.5rem;
  border-radius: 3px;
  outline: none;
  border: none;
}
@keyframes fadeIn {
  0% {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.1);
  }
  100% {
  }
}

header,
section {
  position: relative;
  margin-top: 4rem;
  margin-left: 6rem;
  max-width: 700px;
}

@media screen and (max-width: 1440px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  header,
  section {
    margin-left: 2rem;
  }
  .nav {
    padding-left: 2rem;
  }
  .nav .nav-links {
    display: none;
  }
  .nav .fa-bars {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 4rem;
  }
  h2 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2rem;
  }
  p {
    margin: 2rem 0;
  }
  label {
    font-size: 0.9rem;
  }
  input {
    margin-bottom: 1.5rem;
  }
}